import {evalScript} from "./libs/vbcn/scripthandler";
import localstorage from "./components/wishlist/storage/localstorage";

const storageKey = 'bittl-frontend-admin';

if(!localstorage.has(storageKey)) {
	let protoStorage = {
		disableMarker: false,
	};
	localstorage.set(storageKey, protoStorage);
}

export const adminStorage = localstorage.get(storageKey);



function insertMarker() {
	const scriptTag = document.getElementById('marker-insert');
	if(!scriptTag) return;

	if(adminStorage.disableMarker) {
		console.log("marker disabled");
	} else {
		evalScript(scriptTag);
	}
}

function rewriteFindologicProductUrls() {
	const contentContainer = document.querySelector('.fl-content-container');

	if(!contentContainer) {
		return;
	}

	const allProducts = contentContainer.querySelectorAll('.fl-product');

	if(allProducts.length === 0) {
		return;
	}

	Array.from(allProducts).forEach(product => {
		try {
			if(product.dataset.link.startsWith('http')) {
				product.dataset.link = new URL(product.dataset.link).pathname
			}
		} catch (error) {
			console.log(error);
		}

		let allProductLinks = product.querySelectorAll('a.fl-product-image-link, a.fl-product-infos, a.fl-variant-item');

		Array.from(allProductLinks).forEach(link => {
			try {
				if(link.href.startsWith('http')) {
					link.href = new URL(link.href).pathname;
				}
			} catch (error) {
				console.log(error);
			}
		});
	});


	// const notificationContainer = document.createElement('div');
	// addClass(notificationContainer, 'notification-container');
	// notificationContainer.innerHTML = `
	// 	    <div class="notification is-warning">
	// 			replaced all findologic product urls with relative paths. <br>
	// 			this might lead to links that do not lead to products because the findologic state might differ in dev systems.
	// 		</div>`
	// contentContainer.prepend(notificationContainer);

}


insertMarker();

document.addEventListener("findologicFinish", e => {
	console.log('findofinish');
	rewriteFindologicProductUrls();
});

